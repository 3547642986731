import { Injectable } from '@angular/core';
import { environment } from '@environments/environment';
import { IGetQuery, ISearchResponse2 } from 'ets-fe-ng-sdk';
import { BaseService } from '@Services/base.service';
import { TableFieldsDictionaryComponent } from '../table-fields-dictionary/table-fields-dictionary.component';

@Injectable({
  providedIn: 'root',
})
export class DocumentationTableFieldsService extends BaseService {
  readonly baseURL = environment.apiBaseUrl + `/rest/tables/dictionary/`;
  search(
    query: IGetQuery<{
      description: string;
      fieldName: string;
      tableName: string;
    }>,
  ) {
    return this.get<ISearchResponse2<ITableFieldDocumentation>>('search', query);
  }
  searchLite(
    query: IGetQuery<{
      description: string;
      fieldName: string;
      tableName: string;
    }>,
  ) {
    query.pageNumber = query.pageNumber || 1;
    query.pageSize = query.pageSize || 1000000;
    return this.apiS.get<ISearchResponse2<ITableFieldDocumentationLite>>(
      environment.apiBaseUrl + '/rest/tables/field-name/description',
      query,
    );
  }

  save(data: ITableFieldDocumentation[]) {
    debugger;
    return this.post(
      {
        tableDictionaryBulkRequestList: data.map((tableDictionary) => ({
          requestId: this.uS.generateUUID(),
          tableDictionary,
        })),
      },
      'bulk',
    );
  }

  openTableFieldsDictionaryModal() {
    this.uS
      .pageToModalOpener(TableFieldsDictionaryComponent, 'Dictionary', {}, null, {
        width: '90%',
        height: '90%',
      })
      .subscribe((res) => {});
  }
}

export interface ITableFieldDocumentation {
  authBy?: string;
  authOn?: string;
  createdBy?: string;
  createdOn?: string;
  description?: string;
  fieldName: string;
  id?: number;
  tableName?: string;
  updatedBy?: string;
  updatedOn?: string;
  users?: string;
  dates?: string;
}

export type ITableFieldDocumentationLite = Pick<ITableFieldDocumentation, 'description' | 'fieldName'>;
