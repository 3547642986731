import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, inject, input, output, signal } from '@angular/core';
import { toObservable, toSignal } from '@angular/core/rxjs-interop';
import { TableRelationsService } from '@Services/table-relations.service';
import InputComponents from '@Shared/components/input/inputs.component';
import { ITableName } from '@Shared/models/index.model';
import { BtnComponent, LoaderComponent } from 'ets-fe-ng-sdk';
import { switchMap, tap } from 'rxjs';

@Component({
    selector: 'tables-by-group',
    imports: [CommonModule, BtnComponent, InputComponents, LoaderComponent],
    templateUrl: './tables-by-group.component.html',
    styleUrl: './tables-by-group.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class TablesByGroupComponent {
  readonly tableRelationsService = inject(TableRelationsService);

  readonly group = input.required<string>();
  readonly selectedTable = output<ITableName>();

  readonly loading = signal(true);
  readonly group$ = toObservable(this.group);
  readonly tables = toSignal(
    this.group$.pipe(
      switchMap((group) => this.tableRelationsService.getTablesByTableGroup(group)),
      tap(() => this.loading.set(false)),
    ),
  );
}
