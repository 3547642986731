<mat-drawer-container class="example-container">
  <mat-drawer mode="side" opened>
    <div class="p-3" #sideCont>
      <mat-accordion>
        <div class="mb-3">
          <app-btn icon="home" (mclick)="selectTable$.next(null)" />
        </div>
        @for (group of tableGroups(); track group) {
          <mat-expansion-panel>
            <mat-expansion-panel-header (click)="cdr.detectChanges()">
              {{ group }}
            </mat-expansion-panel-header>
            <ng-template matExpansionPanelContent>
              <tables-by-group [group]="group" (selectedTable)="selectTable$.next($event)" />
            </ng-template>
          </mat-expansion-panel>
        }
      </mat-accordion>
    </div>
  </mat-drawer>
  <mat-drawer-content [style.marginLeft.px]="sideCont.offsetWidth + 10">
    <div class="p-3">
      <loader [loading]="loading()">
        @if (!selectedTable()) {
          <div class="row justify-content-start">
            <div class="col-sm-3">
              <app-autocomplete
                [form]="tableSearchForm"
                label="Field Name"
                name="fieldName"
                (mchange)="filterTableFields()"
                [options]="allTableFields()"></app-autocomplete>
            </div>
          </div>
          @if (tableSearchForm.value.fieldName) {
            <app-find-item
              [searchObservableFunc]="search()"
              [autoOrder]="false"
              [displayedColumns]="columns()"
              [showExport]="true"
              label="Table Field Dictionary"
              [centerCells]="true"
              #fi
              [showAdditionalColumns]="false"
              [showClearBtn]="false"
              [showSearchBtn]="false"
              orderField="updatedOn"
              orderDirection="desc" />
          } @else {
            <div class="mt-5">
              <table-plain
                [displayedColumns]="columns()"
                [data]="emptyData()"
                noItemTxt="Please enter a field name"></table-plain>
            </div>
          }
        } @else {
          <div class="mb-3">
            <div class="row g-2 row-cols-lg-auto justify-content-between">
              <div>
                <div class="row row-cols-lg-auto align-items-center g-2">
                  <div class="">
                    <h5 class="m-0">
                      {{ header() | appTranslate | async }} -
                      <span>
                        {{ (tableDescription() | appTranslate | async) || 'null' }}
                      </span>
                    </h5>
                  </div>
                </div>
              </div>
              <div class="">
                <div class="row row-cols-lg-auto align-items-center g-3">
                  <div class="">
                    <app-btn
                      text="Download"
                      icon="download"
                      [mini]="true"
                      (mclick)="downloadTable(dbtn)"
                      #dbtn />
                  </div>
                </div>
              </div>
            </div>
          </div>
          @if (groupMappedFields()) {
            <mat-accordion>
              @for (gi of currentGroupedForms(); track gi.label) {
                <mat-expansion-panel>
                  <mat-expansion-panel-header>
                    {{ gi.label | appTranslate | async }} ({{ gi.forms.length || 0 }})
                  </mat-expansion-panel-header>
                  <ng-template matExpansionPanelContent>
                    <table-plain
                      [pageSize]="10"
                      [showPager]="true"
                      [displayedColumns]="selectedTableCols()"
                      [data]="gi.forms.value"
                      [filterFields]="selectedTableFilterFields()"
                      [centerCells]="true"></table-plain>
                  </ng-template>
                </mat-expansion-panel>
              }
            </mat-accordion>
          }
        }
      </loader>
    </div>
  </mat-drawer-content>
</mat-drawer-container>
