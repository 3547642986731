import { Injectable, inject } from '@angular/core';
import { ApiService, SDKBaseService } from 'ets-fe-ng-sdk';
import { UtilityService } from './utility.service';

@Injectable({
  providedIn: 'root',
})
export class BaseService extends SDKBaseService {
  protected override apiS = inject(ApiService);
  protected uS = inject(UtilityService);
}
