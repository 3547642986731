<loader [loading]="loading()" [height]="200" [text]="''">
  <!-- <div class="">
  <app-input-td-rf placeholder="Filter" [showValidationMsg]="false"  />
  </div> -->
  @for (table of tables(); track table.primaryTable) {
    <div class="">
      <app-btn [mini]="true" mclass="menu-bttn" [text]="table._label" type="clear" (mclick)="selectedTable.emit(table)" />
    </div>
    @if (!$last) {
      <hr />
    }
  }
</loader>
